<script setup lang="ts">
import { filename } from 'pathe/utils'
const localePath = useLocalePath()

const links = [
  { to: '/dashboard', img: 'dashboard', title: 'لوحة التحكم' },
  { to: '/dashboard/profile', img: 'profile', title: 'الملف الشخصي' },
  { to: '/dashboard/vehicles', img: 'vehicle', title: 'مركباتي' },
  { to: '/dashboard/inspections', img: 'inspection', title: 'فحوصاتي' },
  { to: '/evaluations', img: 'eva', title: 'تسعيراتي' },
  { to: '/dashboard/warranties', img: 'warranties', title: 'ضماناتي' },
]

const linksHidden = [
  { to: '/', img: 'qr-code', title: 'قراءات الباركود السابقة' },
  { to: '/', img: 'transaction', title: 'العملياتاالمالية' },
  { to: '/', img: 'coupons', title: 'كوبوناتي' },
  { to: '/branches', img: 'branches', title: 'فروعنا' },
  { to: '/contact', img: 'contact', title: 'تواصل معنا' },
  // { to: '/', img: 'settings', title: 'الإعدادات' },
  // { to: '/', img: 'moon', title: 'Dark Mode' },
]

const glob = import.meta.glob('~/assets/images/*.svg', { eager: true })
const images = Object.fromEntries(Object.entries(glob).map(([key, value]) => [filename(key), value.default]))
console.log(images)
</script>
<template>
  <div class="menu h-100 d-flex align-items-center justify-content-around bg-white shadow-lg">
    <BDropdown
      dropup
      size="sm"
      variant="light"
      no-caret
      menu-class="py-2"
      toggle-class="rounded-circle bg-white border-1 shadow-lg menu__toggle"
      offset="5px"
    >
      <template #button-content>
        <box-icon name="dots-vertical-rounded"></box-icon>
      </template>
      <BDropdownItem v-for="link in linksHidden" :key="link.title" :to="localePath(link.to)">{{
        link.title
      }}</BDropdownItem>
    </BDropdown>
    <NuxtLinkLocale
      v-for="link in links"
      :key="link.title"
      :to="link.to"
      class="menu-link rounded-circle shadow-lg"
      :class="{ active: $route.path === localePath(link.to) }"
    >
      <img :src="images[link.img]" class="p-2" width="100%" height="auto" alt="img" />
    </NuxtLinkLocale>
  </div>
</template>

<style lang="css">
.menu {
  font-family: 'UniversNext';
  background: #ffffff;
  border-top: 1px solid rgba(195, 212, 233, 0.4);
}

.menu__toggle {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-link {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 40px;
  margin-right: 1rem;
}
.menu-link:hover {
  transition: all 0.4s ease-in-out;
  transform: translateY(-4px);
  transform: scale(1.2);
}

.menu-link.active {
  background: #2d3291;
  color: white;
  transition: all 0.3s linear;
}
</style>
