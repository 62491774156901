<script lang="ts" setup>
import { useMediaQuery } from '@vueuse/core'

const isMobile = useMediaQuery('(max-width: 1024px)')

</script>

<template>
  <div class="admin-layout">
    <div class="admin-layout__header">
      <AppHeader />
    </div>

    <div v-if="!isMobile" class="admin-layout__sidebar">
      <AppSideBar />
    </div>
    <main class="admin-layout__main">
      <slot />
    </main>
    <div v-if="isMobile" class="admin-layout__mobile">
      <AppMenuMobile />
    </div>
  </div>
</template>

<style lang="css">
:root {
  --admin-header-height: 97px;
  --admin-footer-height: 70px;
  --admin-nav-width: 327px;
  --admin-mobile-menu-height: 60px;
  --spacing: 2rem;
}

.admin-layout {
  display: grid;
  height: 100vh;

  /* grid-template-rows: var(--admin-header-height) 1fr; */
  grid-template-columns: var(--admin-nav-width) 1fr;
  grid-template-areas:
    'header header'
    'sidebar main';

  /* hide sidebar on mobile */
  @media screen and (max-width: 1024px) {
    --spacing: 1rem;

    grid-template-rows: var(--admin-header-height) 1fr var(--admin-mobile-menu-height);

    grid-template-areas:
      'header header'
      'main main'
      'mobile-menu mobile-menu';
  }

  @media screen and (max-width: 768px) {
    --admin-header-height: 70px;
  }
}

.admin-layout__header {
  grid-area: header;
  max-height: var(--admin-header-height);
}

.admin-layout__sidebar {
  grid-area: sidebar;
  width: var(--admin-nav-width);
  overflow-y: auto;
}

.admin-layout__main {
  grid-area: main;
  padding: var(--spacing);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: #f4f7fa;
}

.admin-layout__mobile {
  grid-area: mobile-menu;
}

.ps {
  height: 100vh;
}
</style>
