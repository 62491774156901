<script lang="ts" setup>
import { filename } from 'pathe/utils'
import { useUserStore, useUserProfileStore } from '@/store/userStore'

const storeUserProfile = useUserProfileStore()
const router = useRouter()
const store = useUserStore()
const localePath = useLocalePath()

const glob = import.meta.glob('~/assets/images/*.svg', { eager: true })
const images = Object.fromEntries(Object.entries(glob).map(([key, value]) => [filename(key), value.default]))

const links = [
  { to: '/dashboard', img: 'dashboard', title: 'لوحة التحكم' },
  { to: '/dashboard/profile', img: 'profile', title: 'الملف الشخصي' },
  { to: '/dashboard/vehicles', img: 'vehicle', title: 'مركباتي' },
  { to: '/dashboard/inspections', img: 'inspection', title: 'فحوصاتي' },
  { to: '/evaluations', img: 'eva', title: 'تسعيراتي' },
  { to: '/dashboard/warranties', img: 'warranties', title: 'ضماناتي' },
  { to: '/dashboard/user-scan-list', img: 'qr-code', title: 'قراءات الباركود السابقة' },
  { to: '/dashboard/transaction', img: 'transaction', title: 'العمليات المالية' },
  { to: '/dashboard/wallet', img: 'wallet2', title: 'محفظتي' },
  { to: '/dashboard/coupons', img: 'coupons', title: 'كوبوناتي' },
  { to: '/branches', img: 'branches', title: 'فروعنا' },
]

const links2 = [
  { to: '/contact', img: 'contact', title: 'تواصل معنا' },
  // { to: '/', img: 'settings', title: 'الإعدادات' },
  // { to: '/', img: 'moon', title: 'Dark Mode' },
]

async function handleLogout() {
  try {
    store.removeUserFromLocalStorage()
    storeUserProfile.removeUserProfileFromLocalStorage()
    router.push(localePath('/'))
  } catch (error) {
    console.log(error)
  }
}
</script>

<template>
  <aside class="sidebar">
    <div class="fs-12 fw-600 text-puprlpeShade mb-3 opacity-40 sidebar__group-title">القائمة الرئيسية</div>
    <ul class="mb-4">
      <AppSideBarItem v-for="item in links" :key="item.title" v-bind="item" :img="images[item.img]" />
    </ul>
    <div class="fs-12 fw-600 text-puprlpeShade opacity-40 mb-3 sidebar__group-title">التفضيلات</div>
    <ul>
      <AppSideBarItem v-for="item in links2" :key="item.title" v-bind="item" :img="images[item.img]" />
      <AppSideBarItem title="تسجيل الخروج" :img="images['logout']" @click="handleLogout" />
    </ul>
  </aside>
</template>

<style lang="css" scoped>
.sidebar {
  font-family: 'UniversNext';
  background: #fff;
  border-right: 1px solid #f3f5f7;
  width: 100%;
  padding: 30px 20px;
  height: 100%;
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  width: 0px;
}

.sidebar .sidebar__group-title {
  display: flex;
  align-items: center;
  font-family: 'UniversNext', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: justify;
  letter-spacing: -0.02em;
  color: #94a7cb;
}
</style>
